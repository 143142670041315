import { LinkResolverFunction } from "@prismicio/helpers"

const linkResolver: LinkResolverFunction = (doc) => {
  switch (doc.type) {
    // case "page":
    //   return doc.uid !== `index` ? `/${doc.uid}` : `/`
    default:
      // return `/${doc.type}s/${doc.uid}`
      return `/`
  }
}

export default linkResolver
export { linkResolver }
