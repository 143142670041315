import React from "react"
import { useJSONCookie } from "../hooks/useCookie"
import { CookieAttributes } from "js-cookie"
import { usePortal } from "../hooks/usePortal"

type ReturnsJSXElement = (
  close: (override?: CookieAttributes) => void,
  open: boolean
) => JSX.Element | JSX.Element[]

const Sidebar = ({
  children,
  closedClassNames = ``,
  openClassNames = ``,
  options = {
    path: `/`,
    expires: 0,
  },
  id,
  x = true,
}: {
  id: string
  children: JSX.Element | JSX.Element[] | ReturnsJSXElement
  closedClassNames?: string
  openClassNames?: string
  options?: CookieAttributes
  x: boolean
}) => {
  const [open, setOpen] = useJSONCookie(id, true, options),
    close = (override?: CookieAttributes, callback?: () => void) => {
      if (open) {
        setOpen(false, !override?.type && override)
        if (callback) callback()
      }
    },
    sidebar = (
      <div
        {...{
          className: `sidebar-container pointer-events-auto w-full ${
            open ? `open ${openClassNames}` : `closed ${closedClassNames}`
          }`,
          "aria-hidden": !open,
        }}
        {...open ? {} : { inert: `true` }}
      >
        <div
          className={`sidebar relative p-0 ${
            x ? `pr-12` : ``
          } bg-black text-white`}
        >
          {x && (
            <button
              className="absolute top-0 right-0 leading-0"
              style={{ fontSize: `1.35rem`, width: `34px`, height: `34px` }}
              onClick={close}
            >
              <span className="sr-only">Close</span>
              <span aria-hidden="true">&times;</span>
            </button>
          )}
          {typeof children === `function` ? children(close, open) : children}
        </div>
      </div>
    )

  return (
    usePortal(sidebar, {
      className: `fixed z-40 pointer-events-none inset-0 flex flex-col justify-start`,
    }) || null
  )
}

export default Sidebar
export { Sidebar }