import { useState, useEffect } from "react"
const useHover = (element) => {
  const [hover, setHover] = useState(false)

  useEffect(() => {
    const node = element.current,
      overHandler = () => setHover(true),
      outHandler = () => setHover(false)

    if (node) {
      // node.addEventListener(`mouseover`, overHandler)
      node.addEventListener(`mouseenter`, overHandler)
      node.addEventListener(`mouseleave`, outHandler)
    }

    return () => {
      if (node) {
        // node.removeEventListener(`mouseover`, overHandler)
        node.removeEventListener(`mouseenter`, overHandler)
        node.removeEventListener(`mouseleave`, outHandler)
      }
    }
  }, [element])

  return hover
}

export { useHover }
