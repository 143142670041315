import { useRef, useEffect } from "react"
import fastdom from "fastdom"
import { throttle } from "throttle-debounce"

const useCursorPosition = (eventElement, primaryInput, callback) => {
  const positionRef = useRef({
      x: -1, y: -1,
      target: null
    })

  const updateMousePosition = (e) => {
    fastdom.measure(() => {
      const newPosition = {
        x: e.x, y: e.y,
        target: e.target
      }

      // If position and cursor are unchanged, break unless target has autoplay attribute
      if(newPosition.x === positionRef.current.x && newPosition.y === positionRef.current.y) {
        if(!newPosition.target.getAttribute(`data-autoplay`)) return
      }

      // Update positionRef, and store newPosition on window
      // so we can trigger fake mousemove events from autoplay carousels
      positionRef.current = newPosition
      window.cursorPosition = newPosition
      if(typeof callback === `function`) callback(positionRef.current)
    })
  }

  const throttleUpdateMousePosition = throttle(10, updateMousePosition)

  const setMouseOut = (e) => {
    fastdom.measure(() => {
      positionRef.current = {
        x: -1, y: -1,
        target: e.target
      }
      if(typeof callback === `function`) callback(positionRef.current)
    })
  }

  useEffect(() => {
    if(primaryInput !== `mouse`) return

    if (eventElement) {
      eventElement.addEventListener(`pointermove`, updateMousePosition, { passive: true })
      document.documentElement.addEventListener(`pointerleave`, setMouseOut)
    }

    return () => {
      if (eventElement) {
        eventElement.removeEventListener(`pointermove`, updateMousePosition, { passive: true })
        document.documentElement.removeEventListener(`pointerleave`, setMouseOut)
      }
    }
  }, [eventElement])

  return positionRef
}

export default useCursorPosition
export { useCursorPosition }