import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import usePrevious from "../hooks/usePrevious"
import { AnimatePresence, LazyMotion, m } from "framer-motion"
import { Layout } from "./layout"
import { GatsbyBrowser, GatsbySSR } from "gatsby"
import {
  BrowserInfo,
  SearchBotDeviceInfo,
  BotInfo,
  NodeInfo,
  ReactNativeInfo,
  detect
} from "detect-browser"

const loadDomMax = () =>
  import("../utilities/loadDomMax").then((res) => res.default)

const PageWrapper = ({
  element,
  props,
}: GatsbyBrowser<any, any, any>["wrapPageElement"] | GatsbySSR<any, any>["wrapPageElement"]) => {
  const [browser, setBrowser] = useState<BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo | null>(null),
    [reduceMotion, setReduceMotion] = useState(false),
    [transitioning, setTransitioning] = useState(false),
    { pathname } = useLocation(),
    previousPathname = usePrevious(pathname),
    pathnameChanged = previousPathname !== undefined && previousPathname !== pathname

  useEffect(() => {
    const browser = detect()
    setBrowser(browser)
    setReduceMotion(
      // true
      // false
      Boolean(
        browser
          && browser?.name === `safari`
          && browser?.os === `Mac OS`
      )
    )
  }, [])

  useEffect(() => {
    if(reduceMotion) return
    if(pathnameChanged) setTransitioning(true)
  }, [pathnameChanged, reduceMotion])

  const pageWrapper = reduceMotion ? (
      <LazyMotion features={loadDomMax} strict>
        <Layout {...props} browser={browser} reduceMotion={reduceMotion} transitioning={transitioning}>
          <div className="fixed inset-0 z-0 overflow-auto" data-scroll-root={pathname} key={pathname}>
            {element}
          </div>
        </Layout>
      </LazyMotion>
    ) : (
      <LazyMotion features={loadDomMax} strict>
        <Layout {...props} browser={browser} reduceMotion={reduceMotion} transitioning={transitioning}>
          <AnimatePresence
            initial={false}
            mode="popLayout"
            onExitComplete={() => {
              setTransitioning(false)
            }}
          >
            <div key={pathname}>
              <m.div
                className="fixed inset-0 z-0 overflow-auto"
                data-scroll-root={pathname}
                initial={{
                  opacity: 0,
                  y: `100vh`,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                }}
                exit={{
                  opacity: 0,
                  y: `-100vh`,
                }}
                transition={{
                  duration: 0.7,
                }}
              >
                {element}
              </m.div>
            </div>
          </AnimatePresence>
        </Layout>
      </LazyMotion>    
    )

  // const pageWrapper = (
  //   <Layout {...props} browser={browser} reduceMotion={reduceMotion} transitioning={transitioning}>
  //     <div className="fixed inset-0 z-0 overflow-auto" data-scroll-root={pathname} key={pathname}>
  //       {element}
  //     </div>
  //   </Layout>
  // )

  return pageWrapper
}

export { PageWrapper }