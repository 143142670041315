import React, { useCallback, useRef } from "react"
import { useCursorPosition } from "../utilities/useCursorPosition"
import { windowIfAvailable } from "../utilities/windowIfAvailable"
import useEventListener from "../hooks/useEventListener"
import fastdom from "fastdom"

const Cursor = ({ primaryInput }: any) => {
  // Let elements request the cursor be displayed via class
  const cursorRef = useRef<HTMLDivElement>(null),
    innerCursorRef = useRef<HTMLDivElement>(null),
    computeInnerHTML = (arrow, cursorText) => `
      <span class="${arrow === `LEFT` ? `inline-block` : `hidden`} mr-2.5">←</span>
      ${cursorText}
      <span class="${arrow === `RIGHT` ? `inline-block` : `hidden`} ml-2.5">→</span>
    `,
    previousCalculations = useRef({
      arrow: `NONE`,
      cursorText: `•`,
      direction: 1,
      innerHTML: computeInnerHTML(`NONE`, `•`),
      isDark: false,
      isLink: false,
      isVisible: false,
      left: `-1px`,
      length: 1,
      mutate: true,
      mutateClasses: true,
      mutateHTML: true,
      mutatePosition: true,
      requestsCursor: false,
      top: `-1px`,
    }),
    positionCallback = useCallback(
      (position, cursorOverride) => {
        // fastdom.measure(() => {
          const calculations = { ...previousCalculations.current },
          addClasses: string[] = [],
          removeClasses: string[] = []

          // Read from cursor
          calculations.arrow = `NONE`
          calculations.cursorText = cursorOverride ?? position?.target?.getAttribute(`data-cursor`) ?? `•`
          calculations.direction = position?.target?.classList?.contains(`prev`) ? -1 : 1
          calculations.innerHTML = ``
          calculations.isDark = position?.target?.matches(`[data-theme="dark"],[data-theme="dark"] *`)
          calculations.isLink = [`A`, `BUTTON`].indexOf(position?.target?.tagName) > -1
          calculations.isVisible = position.x >= 0 && position.y > 0
          calculations.left = `${position.x}px`
          calculations.length = position?.target?.getAttribute(`data-length`)
          calculations.requestsCursor = position?.target?.classList?.contains(`cursor`)
          calculations.top = `${position.y}px`
            
          if(calculations.requestsCursor && calculations.cursorText !== `1/1`) {
            if(calculations.direction === -1) calculations.arrow = `LEFT`
            else calculations.arrow = `RIGHT`
          }
    
          calculations.innerHTML = computeInnerHTML(calculations.arrow, calculations.cursorText)
    
          // Visibility comparison
          if(calculations.isVisible !== previousCalculations.current.isVisible) {
            if(calculations.isVisible) removeClasses.push(`hidden`)
            else addClasses.push(`hidden`)
          }
    
          // Direction changed
          if((calculations.direction > 0) !== (previousCalculations.current.direction > 0)) {
            if(calculations.direction > 0) addClasses.push(`-translate-x-full`)
            else removeClasses.push(`-translate-x-full`)
          }
    
          // CursorText changed
          if((calculations.cursorText === `•`) !== (previousCalculations.current.cursorText === `•`)) {
            if(calculations.cursorText === `•`) addClasses.push(`text-xl`, `-translate-x-1/2`)
            else removeClasses.push(`text-xl`, `-translate-x-1/2`)
          }
    
          // IsLink changed
          if(calculations.isLink !== previousCalculations.current.isLink) {
            if(calculations.isLink) {
              addClasses.push(`text-red-600`)
              removeClasses.push(`text-black`, `text-white`)
            } else {
              removeClasses.push(`text-red-600`)
              if(calculations.isDark) addClasses.push(`text-white`)
              else addClasses.push(`text-black`)
            }
          }
    
          // IsDark changed
          if(calculations.isDark !== previousCalculations.current.isDark) {
            if(calculations.isDark) {
              if(!calculations.isLink) addClasses.push(`text-white`)
              removeClasses.push(`text-black`)
            } else {
              if(!calculations.isLink) addClasses.push(`text-black`)
              removeClasses.push(`text-white`)
            }
          }

          // Determine if mutations should be made
          calculations.mutateClasses = addClasses.length > 0 || removeClasses.length > 0
          calculations.mutateHTML = calculations.innerHTML !== previousCalculations.current.innerHTML
          calculations.mutatePosition = calculations.left !== previousCalculations.current.left || calculations.top !== previousCalculations.current.top
          calculations.mutate = calculations.mutateClasses || calculations.mutateHTML || calculations.mutatePosition

          // Update calculations
          previousCalculations.current = calculations

          if(calculations.mutate) {
            fastdom.mutate(() => {
              if(!cursorRef.current || !innerCursorRef.current) return
      
              // Update position
              if(calculations.mutatePosition) cursorRef.current.style.transform = `translate3d(${calculations.left}, ${calculations.top}, 0)`
        
              // innerHTML comparison
              if(calculations.mutateHTML) innerCursorRef.current.innerHTML = calculations.innerHTML
        
              // Perform class updates
              if(calculations.mutateClasses) {
                innerCursorRef.current.classList.remove(...removeClasses)
                innerCursorRef.current.classList.add(...addClasses)
              }
            })
          }
        // })
      }, []
    ),
    positionRef = useCursorPosition(
      windowIfAvailable(),
      primaryInput,
      positionCallback
    )

  useEventListener(`mouseup`, (e) => {
    fastdom.measure(() => {
      const target = e.target as HTMLElement
      if(target.classList.contains(`prev`) || target.classList.contains(`next`)) {
        const cursorOverride = target.getAttribute(`data-cursor`)
        positionCallback(positionRef.current, cursorOverride)
      }
    })
  }, windowIfAvailable(), { passive: true })

  return (
    <div
      className="cursor-element fixed pointer-events-none z-50 top-0 left-0 w-max h-max touch:hidden mouse:block mouse:will-change-transform [backface-visiblity:hidden]"
      ref={cursorRef}
    >
      <div
        className={`inset-0 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap tabular-nums text-black text-xl transition-[color,opacity] mouse:will-change-contents duration-200`}
        dangerouslySetInnerHTML={{ __html: previousCalculations.current.innerHTML }}
        ref={innerCursorRef}
      />
    </div>
  )
}

export default Cursor
export { Cursor }