import React, { useState, useRef, useEffect } from "react"
import { Logo } from "./Logo"
import slugify from "slugify"
import { PrismicRichText } from "@prismicio/react"
import { useHover } from "../utilities/useHover"
import { useLocation } from '@reach/router';
import { AnimatePresence, HTMLMotionProps, m } from "framer-motion"
import { Link } from "gatsby"
import useEventListener from "../hooks/useEventListener"
import fastdom from "fastdom"

const FramerNavBar = ({
  client,
  clientHasCarousel,
  clientIndex,
  slideIndex,
  setMenuIsVisible
}: {
  client: any,
  clientHasCarousel: boolean,
  clientIndex: number,
  slideIndex: number,
  setMenuIsVisible: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [isExpanded, setIsExpanded] = useState(false),
    navRef = useRef(null),
    buttonRef = useRef(null)

  const navHover = useHover(navRef),
    location = useLocation(),
    isHome = location.pathname === `/`,
    isWork = location.pathname === `/work` || location.pathname === `/work/`,
    hasDescription = client?.description?.richText?.length > 0

  // console.log(client)

  // const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if(isExpanded) {
      // Ensure client panel closes on homepage
      // Ensure client panel closes when nav is not hovered
      // Ensure client panel closes when client has no description
      if(isHome || !navHover || !hasDescription) {
        // console.log(`setIsExpanded false`)
        setIsExpanded(false)
      }
    }
  }, [isExpanded, hasDescription, isHome, navHover])

  const toggleExpanded = (e) => {
    // console.log(`toggleExpanded`, e, isExpanded, isHome, client, hasDescription, document.location.pathname)
    // Close unconditionally
    if(isExpanded) setIsExpanded(false)
    // Open only on work page for clients with descriptions
    else if(!isHome && hasDescription) {
      // console.log(`setIsExpanded true`)
      setIsExpanded(true)
    }
  }

  const transitionProps = {
    entry: {
      length: 0,
      zIndex: 1,
    },
    exit: {
      length: 0.7,
      zIndex: 2,
    },
  }

  const navMotionProps: HTMLMotionProps<div> = {
    initial: "hidden",
    animate: "visible",
    exit: "exit",
    variants: {
      hidden: {
        opacity: 0,
        y: 2,
      },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          delay: 0.33,
          duration: 0.33,
        },
      },
      exit: {
        opacity: 0,
        y: 0,
        transition: {
          duration: 0.33,
        }
      }
    },
  }

  const clientName = client?.name ?? `\u00a0`,
    clientNameSlug = slugify(client?.name?.toLowerCase() ?? ``),
    newClientNameElement = (
      <m.span
        className={`pointer-events-auto absolute top-0 left-0 inline-block p-2.5 box-content text-left tabular-nums ${isHome ? `cursor-auto` : ``} ${isExpanded ? `text-black` : ``} overflow-hidden whitespace-nowrap`}
        key={clientName}
        ref={buttonRef}
        onClick={toggleExpanded}
        onMouseEnter={toggleExpanded}
        {...navMotionProps}
      >
        {isHome
          ? clientHasCarousel
            ? (
              <Link
                className="p-2.5 -mx-2.5"
                state={{ client: clientNameSlug }}
                to={`/work`}
                {...transitionProps}
              >
                {clientName}
              </Link>
            ) : (
              <span className="text-black text-opacity-40">
                {clientName}
              </span>
          ) : clientName
        }
      </m.span>
    )

  const { pathname } = useLocation(),
    scrollRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    scrollRef.current = document.querySelector(`[data-scroll-root="${pathname}"]`)
    window.scrollRef = scrollRef
  }, [pathname])

  useEventListener(`wheel`, (e) => {
    // No need to scroll on home
    if(isHome) return
    fastdom.mutate(() => {
      const scrollTop = scrollRef.current?.scrollTop,
        deltaY = e.deltaY
      if(scrollRef.current) scrollRef.current.scrollTop = scrollTop + deltaY
    })
  }, navRef.current, { passive: true })

  return (
    <div ref={navRef}>
      <div
        className="pointer-events-none fixed z-20 top-0 left-0 right-0 w-full text-base"
        data-theme="light"
      >
        <div className="flex items-center justify-between p-2.5">
          {/* Desktop nav */}
          <div className="hidden sm:flex relative items-center">
            <div className="p-2.5 tabular-nums invisible">
              {isHome ? `Work` : `Home`}
            </div>
            <AnimatePresence>
              <m.div
                key={isHome ? `work` : `home`}
                className={`absolute pointer-events-auto`}
                {...navMotionProps}
              >
                {isHome ? (
                  <Link className="p-2.5" to="/work" {...transitionProps}>Work</Link>
                ) : (
                  <Link className="p-2.5" to="/" {...transitionProps}>Home</Link>
                )}
              </m.div>
            </AnimatePresence>
            <div className="relative">
              <div className="p-2.5 tabular-nums invisible">00</div>
              <AnimatePresence>
                <m.div
                  key={isHome ? `${slideIndex + 1}` : isWork ? `${clientIndex + 1}` : ``}
                  className="pointer-events-auto absolute top-0 left-0 p-2.5 tabular-nums"
                  {...navMotionProps}
                >
                  {isHome && `${slideIndex + 1}`.padStart(2, `0`)}
                  {isWork && `${clientIndex + 1}`.padStart(2, `0`)}
                </m.div>
              </AnimatePresence>
            </div>
            <div className="relative inline-flex">
              <div className="p-2.5 invisible">{clientName}</div>
              <AnimatePresence>
                {newClientNameElement}
              </AnimatePresence>
            </div>
          </div>
          {/* Desktop logo */}
          <Link
            className={`hidden sm:block sm:pointer-events-auto ${isHome || isExpanded ? `md:opacity-0 md:pointer-events-none` : `md:opacity-100 md:pointer-events-auto`} sm:opacity-0 sm:pointer-events-none sm:absolute sm:top-2.5 sm:left-1/2 sm:transform sm:-translate-x-1/2 p-2.5 mt-0.5 mr-2.5 sm:mr-0 text-base ${isExpanded ? 'text-white' : ''} text-opacity-100 transition-[color,opacity] duration-500`}
            to="/"
            {...transitionProps}
          >
            <Logo className="pointer-events-none w-[131px] fill-current" />
          </Link>
          {/* Mobile logo */}
          <Link
            className={`pointer-events-auto sm:hidden p-2.5 mt-px mr-2.5 text-opacity-100`}
            to="/"
            {...transitionProps}
          >
            <Logo className="pointer-events-none w-[131px] fill-current" />
          </Link>
          {/* Info */}
          <button className="pointer-events-auto ml-auto p-2.5" onClick={() => { setMenuIsVisible(true) }}>
            Info
          </button>
        </div>
      </div>
      <m.div
        key="nav-info-panel"
        className={`pointer-events-auto fixed z-10 top-0 left-0 w-full hidden sm:block text-base text-black bg-white border-b border-black/20 overflow-hidden`}
        animate={isExpanded ? `animate` : `exit`}
        layout="size"
        variants={{
          initial: {
            height: 0,
            y: -1,
            transition: {
              type: `spring`,
              damping: 15,
            },
          } as any,
          animate: {
            height: `auto`,
            y: 0,
            transition: {
              type: `spring`,
              damping: 15,
            },
          } as any,
          exit: {
            height: 0,
            y: -1,
            transition: {
              type: `spring`,
              damping: 15,
              delay: 0.2,
            },
          } as any
        }}
      >
        <div className="invisible px-2.5 pt-[54px] pb-1">
          <div className="invisible px-2.5">Home</div>
          <div className="max-w-xl px-2.5">
            <PrismicRichText field={client?.description?.richText} />
            {client?.url?.url && (
              <p>
                <a href={client?.url?.url} target="blank" rel="noopener">
                  {client?.url?.url.replace(/^https?:\/\//, ``).replace(/^www\./, ``).replace(/\/$/, ``)}
                </a>
              </p>
            )}
          </div>
        </div>
        <AnimatePresence>
          {isExpanded && (
            <m.div
              className="absolute top-0 left-0 right-0 hidden sm:flex px-2.5 pt-[54px] pb-5"
              layout
              key={client?.name}
              {...navMotionProps}
            >
              <div className="invisible px-2.5">Home</div>
              <div className="max-w-xl px-2.5">
                <PrismicRichText field={client?.description?.richText} />
                {client?.url?.url && (
                  <p>
                    <a href={client?.url?.url} target="blank" rel="noopener">
                      {client?.url?.url.replace(/^https?:\/\//, ``).replace(/^www\./, ``).replace(/\/$/, ``)}
                    </a>
                  </p>
                )}
              </div>
            </m.div>
          )}
        </AnimatePresence>
      </m.div>
    </div>
  )
}

const FramerNavBarMemo = React.memo(FramerNavBar)

export {
  FramerNavBarMemo as FramerNavBar
}
