/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as React from "react"
import * as prismic from "@prismicio/client"
import fetch from "node-fetch"
import { PrismicProvider } from "@prismicio/react"
import {
  componentResolverFromMap,
  PrismicPreviewProvider,
} from "gatsby-plugin-prismic-previews"
import { linkResolver } from "./src/utilities/linkResolver"
import { PrismicLink } from "./src/components/PrismicLink"
import { PageWrapper } from "./src/components/PageWrapper"
import "./src/css/index.css"

export const wrapRootElement = ({ element }) => {
  const endpoint = prismic.getEndpoint(process.env.GATSBY_PRISMIC_REPOSITORY),
    client = prismic.createClient(endpoint, { fetch })

  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          componentResolver: componentResolverFromMap({}),
          linkResolver,
          repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY,
        },
      ]}
    >
      <PrismicProvider
        client={client}
        linkResolver={linkResolver}
        richTextComponents={{
          heading1: ({ children }) => <h1 className="text-xl">{children}</h1>,
          heading2: ({ children }) => <h2 className="text-base">{children}</h2>,
          hyperlink: ({ children, node: { data } }) => <PrismicLink link={data}>{children}</PrismicLink>,
          paragraph: ({ children }) => <p className="text-base">{children}</p>,
        }}
      >
        {element}
      </PrismicProvider>
    </PrismicPreviewProvider>
  )
}

export const wrapPageElement = (props) => (
  <PageWrapper {...props} />
)