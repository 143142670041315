import { useEffect } from "react"
import { windowIfAvailable } from "./windowIfAvailable"

const throttle = (timer) => {
  // Create a callback store
  let queuedCallback: null | (() => void)
  // Return a function accepting a callback
  return (callback: () => void) => {
    // If no callback is stored
    if (!queuedCallback) {
      // Request animation frame
      timer(() => {
        // Grab the currently stored callback
        const currentCallback = queuedCallback
        // Empty the callback storage
        queuedCallback = null
        // If the current callback is a function, run it
        if(typeof currentCallback === `function`) currentCallback()
      })
      // End animation frame
    }
    // Queue the callback passed to throttle
    queuedCallback = callback
  }
}

const throttledWrite = throttle(windowIfAvailable()?.requestAnimationFrame)

const useThrottledWrite = (callback: (() => void | (() => void)), dependencies: any[] = []) => {
  useEffect(() => {
    throttledWrite(callback)
  }, dependencies)
}

export { throttledWrite, useThrottledWrite }