import React from "react"

const Logo = (props: any) => (
  <svg viewBox="0 0 177.18 14.99" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m4.53 12.6c1.02 0 1.54-.89 1.54-3.57v-8.8h3.09v9.75c0 4.32-2.09 4.99-4.65 4.99-3.19 0-4.5-1.42-4.5-4.46l3.05-.17v.17c0 1.63.46 2.09 1.48 2.09z" />
    <path d="m14.68.23h3.49l4.06 14.57h-3.09l-.82-3.6h-3.62l-.74 3.6h-2.96zm3.09 8.65-.72-3.11c-.4-1.69-.57-2.66-.59-3.32h-.04c-.02.66-.21 1.63-.57 3.32l-.66 3.11z" />
    <path d="m25.66.23 1.5 7.36c.28 1.33.63 3.51.63 4.04h.08c0-.53.36-2.71.57-4.04l1.19-7.36h3.41l-3.41 14.57h-3.36l-3.68-14.57z" />
    <path d="m37.29.23h3.49l4.06 14.57h-3.09l-.82-3.6h-3.62l-.74 3.6h-2.96zm3.09 8.65-.72-3.11c-.4-1.69-.57-2.66-.59-3.32h-.04c-.02.66-.21 1.63-.57 3.32l-.66 3.11z" />
    <path d="m50.72 14.99c-3.05 0-4.32-1.18-4.32-4.12l2.96-.08c0 1.42.57 1.86 1.44 1.86 1.06 0 1.5-.61 1.5-1.95 0-1.44-.42-1.78-1.25-2.03l-1.35-.4c-1.75-.53-3.15-1.39-3.15-4.04s1.37-4.23 4.48-4.23c2.75 0 3.87 1.16 3.87 3.76l-2.88.09c0-1.18-.42-1.48-1.23-1.48-.85 0-1.33.55-1.33 1.63s.49 1.63 1.18 1.88l1.16.42c2.45.89 3.41 1.46 3.41 4.29 0 2.52-1.18 4.4-4.5 4.4z" />
    <path d="m61.98.23h2.9v12.21h5.71v2.37h-8.61z" />
    <path d="m72.82.23h8.25v2.37h-5.35v3.57h4.57v2.35h-4.57v3.91h5.35v2.37h-8.25z" />
    <path d="m83.46.23h2.88v5.63h3.66v-5.63h2.85v14.57h-2.85v-6.6h-3.66v6.6h-2.88z" />
    <path d="m106 .23v14.57h-3.34l-4.04-11.67h-.06c.13.57.23 2.35.23 4.02v7.66h-2.85v-14.58h3.91l3.64 10.72h.11c-.13-.57-.25-2.73-.25-4.4v-6.32h2.66z" />
    <path d="m117.15 14.99c-3.05 0-4.32-1.18-4.32-4.12l2.96-.08c0 1.42.57 1.86 1.44 1.86 1.06 0 1.5-.61 1.5-1.95 0-1.44-.42-1.78-1.25-2.03l-1.35-.4c-1.75-.53-3.15-1.39-3.15-4.04s1.37-4.23 4.48-4.23c2.75 0 3.87 1.16 3.87 3.76l-2.88.09c0-1.18-.42-1.48-1.23-1.48-.85 0-1.33.55-1.33 1.63s.49 1.63 1.18 1.88l1.16.42c2.45.89 3.41 1.46 3.41 4.29 0 2.52-1.18 4.4-4.5 4.4z" />
    <path d="m127.02 2.6h-3.85v-2.37h10.6v2.37h-3.85v12.2h-2.9z" />
    <path d="m140.64 14.97c-2.32 0-4.8-1.16-4.8-5.69v-9.05h3.04v8.8c0 2.47.32 3.57 1.75 3.57s1.74-1.1 1.74-3.57v-8.8h3.09v9.05c0 4.53-2.26 5.69-4.82 5.69z" />
    <path d="m158.35 6.47v1.23c0 5.03-1.54 7.1-5.31 7.1h-4.53v-14.57h4.53c3.42 0 5.31 1.86 5.31 6.24zm-5.24 5.96c1.95 0 2.39-1.57 2.39-4.63v-1.27c0-2.47-.63-3.93-2.39-3.93h-1.69v9.84h1.69z" />
    <path d="m161.01.23h2.9v14.57h-2.9z" />
    <path d="m166.5 7.49c0-5.69 1.84-7.49 5.33-7.49s5.35 1.8 5.35 7.49-1.86 7.49-5.35 7.49-5.33-1.78-5.33-7.49zm5.37 5.12c1.69 0 2.45-.57 2.45-4.38v-2.03c0-3.15-1.1-3.83-2.47-3.83s-2.45.68-2.45 3.78v2.05c0 3.95.87 4.4 2.47 4.4z" />
  </svg>
)

const LogoMemo = React.memo(Logo)

export { LogoMemo as Logo }