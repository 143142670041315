module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"MC5ZZXNoaEJFQUFDQUFzLUxv.77-9KDQ977-977-977-977-9Me-_vUrvv73vv71qWO-_ve-_vVAk77-9SO-_ve-_vTACIu-_ve-_vSk377-977-9","imageImgixParams":{"auto":"format","dpr":1,"fit":"max","q":65},"imagePlaceholderImgixParams":{"w":2,"q":2},"lang":"*","repositoryName":"javaslehn","promptForAccessToken":true,"apiEndpoint":"https://javaslehn.cdn.prismic.io/api/v2","pageSize":100,"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-33633442-1"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Full Name","short_name":"Name","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2d01a1f99a0ecd96757180778fb4adde"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
