import React, { useEffect, useState, useRef } from "react"
import { Sidebar } from "./sidebar"
import { useJSONCookie } from "../hooks/useCookie"
import Cookies from "js-cookie"
import useEventListener from "../hooks/useEventListener"
import { Helmet } from "react-helmet"
import { throttledWrite } from "../utilities/throttle"

declare global {
  interface Window {
    [key: string]: any
  }
}

const sendPageview = () => {
  window[`ga-disable-${process.env.GATSBY_GOOGLE_GTAG_ID}`] = false
  if (typeof window.ga === `function`) window.ga(`send`, `pageview`)
}

const GDPR = () => {
  const [gdpr] = useJSONCookie(`gdpr`, true),
    [gdprHeight, setGdprHeight] = useState(0),
    gdprRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if(gdprRef?.current?.offsetHeight !== gdprHeight) setGdprHeight(gdprRef?.current?.offsetHeight ?? 0)
  }, [])

  // When GDPR cookie changes
  useEffect(() => {
    // Update ga-disable immediately
    window[`ga-disable-${process.env.GATSBY_GOOGLE_GTAG_ID}`] = gdpr

    // If the banner has been dismissed, do nothing
    if (!gdpr) return

    // If the banner is not dismissed, clear any existing cookies
    const cookies = Cookies.get(),
      cookieNames = Object.keys(cookies)
    cookieNames.forEach((name) => Cookies.remove(name))
  }, [gdpr])

  useEventListener(`resize`, () => {
    throttledWrite(() => {
      if(!gdpr || !open) return
      if(gdprRef?.current?.offsetHeight !== gdprHeight) setGdprHeight(gdprRef?.current?.offsetHeight ?? 0)
    })
  }, typeof window === `undefined` ? {} : window, { passive: true })

  return (
    <>
      <Helmet>
        <script>{`window["ga-disable-${process.env.GATSBY_GOOGLE_GTAG_ID}"] = ${gdpr};`}</script>
      </Helmet>
      <Sidebar
        openClassNames="transition-opacity opacity-100 duration-300"
        closedClassNames="transition-opacity opacity-0 duration-300"
        id="gdpr"
        x={false}
      >
        {(close, open) => (
          <div
            className="flex flex-col sm:flex-row sm:items-center p-6 text-sm"
            data-theme="dark"
            ref={gdprRef}
          >
            {/* {open ? (
              <style>{`
                body {
                  padding-bottom: ${(gdprRef?.current?.offsetHeight ?? 0) + 20}px;
                }
              `}</style>
            ) : null} */}
            <p className="flex-initial max-w-[550px] text-12px sm:mr-6">We use cookies and similar technologies to enhance site navigation, analyze site usage, and assist our marketing efforts. By continuing to use this website, you agree to these conditions of use. By continuing to use this website, you agree to these conditions of use.</p>
            <div className="mt-6 sm:mt-0 flex-initial sm:ml-auto">
              <button
                className="w-max text-xl sm:text-right"
                onClick={() => {
                  close({ expires: 365 })
                  sendPageview()
                }}
              >
                Accept <span className="pointer-events-none text-white">cookies</span>
              </button>
            </div>
          </div>
        )}
      </Sidebar>
    </>
  )
}

const GDPRMemo = React.memo(GDPR)

export { GDPRMemo as GDPR }