import { useState, useEffect } from "react"

const useDocumentReady = (): boolean => {
    // State and setter for storing document ready state
    const [ready, setReady] = useState<boolean>(false)

    const testAndSetDocumentReady = () => {
      const newReadyState = document.readyState === `complete`
      if(ready !== newReadyState) setReady(newReadyState)
    }

    useEffect(() => {
      testAndSetDocumentReady()
      document.addEventListener(`readystatechange`, testAndSetDocumentReady)

      return (() => {
        document.removeEventListener(`readystatechange`, testAndSetDocumentReady)
      })
    }, [])

    return ready
}

export { useDocumentReady }