import React, { useEffect, useState } from "react"
import { Logo } from "./Logo"
import { PrismicRichText } from "@prismicio/react"
import "wicg-inert"

import { m } from "framer-motion"
import usePrevious from "../hooks/usePrevious"

const FramerSideMenu = ({
  capabilities,
  clients,
  colophon,
  contact,
  description,
  formal_name,
  menuIsVisible,
  setMenuIsVisible,
}: any) => {
  const [activeLink, setActiveLink] = useState(0),
    previousActiveLink = usePrevious(activeLink),
    getLinkProps = (index: number, activeIndex: number) => {
      const active = index === activeIndex,
        previousActive = index === previousActiveLink
      return {
        "aria-hidden": !active,
        className: `absolute w-full max-w-full px-10 sm:px-0 sm:w-[60%] ${active ? `` : `pointer-events-none`}`,
        variants: {
          visible: {
            opacity: 1,
            y: 0,
            transition: {
              delay: 0.3,
            },
          },
          hidden: {
            opacity: 0,
            y: 5,
          },
          exit: {
            opacity: 0,
          }
        },
        animate: active ? `visible` : previousActive ? `exit` : `hidden`,
      }
    },
    [linkProps, setLinkProps] = useState([0, 1, 2, 3].map(i => getLinkProps(i, activeLink))),
    clientString = clients.map((client: any) => {
      return client.client?.document?.data?.name ?? false
    }).filter((client: string) => client).join(', '),
    linkContents = [`Overview`, `Clients`, `Capabilities`, `Contact`, `Credits`],
    year = new Date().getFullYear()

  useEffect(() => {
    setLinkProps([0, 1, 2, 3, 4].map(i => getLinkProps(i, activeLink)))
  }, [activeLink])

  return (
    <div
      aria-hidden={!menuIsVisible}
      className={`${menuIsVisible ? '' : 'pointer-events-none'} z-30 fixed h-screen w-full inset-0 overflow-x-hidden flex text-base text-white`}
      {... menuIsVisible ? {} : {
        inert: `true`
      }}
    >
      {/* Invisible target to close when click occurs outside */}
      <button className="hidden sm:flex sm:w-full" onClick={() => setMenuIsVisible(false)} />
      {/* Sidemenu */}
      <m.div
        className={`relative top-0 h-screen flex flex-col bg-black w-full`}
        data-theme="dark"
        initial="hidden"
        animate={menuIsVisible ? `visible` : `hidden`}
        variants={{
          visible: {
            x: 0,
            transition: {
              type: `spring`,
              damping: 18,
              duration: 0.7,
            },
          },
          hidden: {
            x: `100%`,
            transition: {
              type: `spring`,
              damping: 18,
              duration: 0.7,
            },
          }
        }}
      >
        {/* Header */}
        <div className="side-navbar z-10 flex flex-wrap sm:flex-nowrap w-full h-auto justify-between p-2.5 sticky">
          {/* Logo */}
          <m.div
            animate={menuIsVisible ? { opacity: 1} : { opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Logo className="sm:hidden w-[132px] mt-px p-2.5 box-content fill-white inline-block" />
          </m.div>
          {/* Links */}
          <div className="flex flex-col sm:flex-row w-full mt-24 sm:mt-0 mb-10 sm:mb-0 order-last sm:order-none">
            {linkContents.map((link, index) => (
              <button
                className={`cursor-pointer text-left px-[30px] sm:p-2.5 ${index === activeLink ? 'text-opacity-100' : ''}`}
                onClick={() => setActiveLink(index)}
                key={`sidemenu-${link}`}
              >
                {link}
              </button>
            ))}
          </div>
          {/* Close */}
          <button
            className="p-2.5 text-opacity-100"
            onClick={() => setMenuIsVisible(false)}
          >
            Close
          </button>
        </div>
        {/* Content */}
        <div className="sm:absolute sm:inset-0 sm:w-full sm:h-full flex flex-col sm:flex-row items-start sm:items-center sm:justify-center">
          <m.div {...linkProps[0]}>{description}</m.div>
          <m.div {...linkProps[1]}>{clientString}</m.div>
          <m.div {...linkProps[2]}>
            <PrismicRichText
              field={capabilities?.richText}
              components={{
                paragraph: ({ children }) => <p>{children}</p>,
              }}
            />
          </m.div>
          <m.div {...linkProps[3]}>
            <PrismicRichText
              field={contact?.richText}
              components={{
                paragraph: ({ children }) => <p>{children}</p>,
              }}
            />
          </m.div>
          <m.div {...linkProps[4]}>
            <PrismicRichText
              field={colophon?.richText}
              components={{
                paragraph: ({ children }) => <p>{children}</p>,
              }}
            />
            <p>
              Site by <a href={`https://studio.sl`} target="blank" rel="noopener">Spevack Loeb</a>
            </p>
          </m.div>
        </div>
        {/* Footer */}
        <p className="absolute left-1/2 bottom-5 transform -translate-x-1/2 text-sm text-center text-white text-opacity-30">
          © {year} {formal_name ?? `Javas Lehn Studio LLC`}.<br className="block lg:hidden" />{` `}All rights reserved.
        </p>
      </m.div>
    </div>
  )
}

const FramerSideMenuMemo = React.memo(FramerSideMenu)

export {
  FramerSideMenuMemo as FramerSideMenu
}
