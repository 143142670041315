import React from "react"

const Filters = ({
  categoryCounts,
  className,
  filters,
  setFilters
}: {
  categoryCounts: { [key: string]: number },
  className?: string,
  filters: string[],
  setFilters: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const categories = Object.keys(categoryCounts),
    toggleCategoryFilter = (category: string) => {
      const newFilters = filters.slice(),
        categoryIndex = newFilters.indexOf(category)

      if(categoryIndex > -1) newFilters.splice(categoryIndex, 1)
      else {
        newFilters.push(category)
        newFilters.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1)
      }

      setFilters(newFilters)
    },
    filtersActive = filters.length > 0
  
  return (
    <div className={`hidden sm:block fixed z-10 bottom-0 left-0 m-5 w-[333px] text-sm ${className}`}>
      <div className="group">
        <button
          className={`cursor-pointer inline-block mr-2 text-black ${filtersActive ? `mouse:hover:text-red-600 mouse:focus-visible:text-red-600`: ``} transition-colors duration-200`}
          onClick={filtersActive
            ? () => {
              setFilters([])
              if(document.activeElement) document.activeElement.blur()
            } : () => { /* */ }
          }
        >
          {filtersActive ? `×` : `+`}
        </button>
        <span
          className={`absolute bottom-0 left-4 text-left text-sm text-black text-opacity-40 mouse:group-hover:opacity-0 mouse:group-hover:pointer-events-none group-focus-within:opacity-0 group-focus-within:pointer-events-none transition-opacity duration-200`}
        >
          {filtersActive
            ? filters.map((filter, i, a) => (
              <React.Fragment key={filter}>
                <span className="whitespace-nowrap">
                  {filter} ({categoryCounts[filter]})
                </span>{i + 1 !== a.length ? `, ` : null}
              </React.Fragment>
            )) : (
              <>Filter</>
            )
          }
        </span>
        <span className="absolute bottom-0 left-4 w-full opacity-0 pointer-events-none mouse:group-hover:opacity-100 mouse:group-hover:pointer-events-auto group-focus-within:opacity-100 group-focus-within:pointer-events-auto text-black text-opacity-40 transition-opacity ease-in duration-200">
          {categories
            ?.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1)              
            ?.map((category, i, a) => {
              const categoryActive = filters.includes(category)
              return (
                <React.Fragment key={category}>
                  <button
                    className={`text-sm text-black ${categoryActive ? 'text-opacity-100 mouse:hover:text-red-600 mouse:focus-visible:text-red-600' : 'text-opacity-40 mouse:hover:text-opacity-100 mouse:focus-visible:text-opacity-100'} transition-colors duration-200`}
                    tabIndex={0}
                    onClick={() => {
                      toggleCategoryFilter(category)
                      if(document.activeElement) document.activeElement.blur()
                    }}
                  >
                    {category}&nbsp;({categoryCounts[category]})
                  </button>{i < a.length - 1 ? `, ` : ``}
                </React.Fragment>
              )
            })
          }
        </span>
      </div>
    </div>
  )
}

export default Filters
export { Filters }
