import React, { ReactElement } from "react"
import { Link } from "gatsby"
import linkResolver from "../utilities/linkResolver"

const PrismicLink = ({ link, children, ...rest }: any): ReactElement => {
  const { link_type, target, type, uid, url, ...linkRest } = link,
    { activeClassName, partiallyActive, ...anchorProps } = rest

  switch(link_type) {
    case `Web`:
      return <a href={url} {...anchorProps} target="blank" rel="noopener">{children}</a>
    case `Document`:
    case `Media`:
      return <Link to={linkResolver(link)} target={target} {...rest}>{children}</Link>
    default:
      return <></>
  }
}

export default PrismicLink
export { PrismicLink }